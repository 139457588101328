import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../../firebaseConfig';
import { logOut } from '../../firebaseConfig';
import '../../fonts/fonts.css';
import LogoImage from '../../images/logo.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right, #f0f3f5, #dce2e8);
  font-family: 'Inter', sans-serif;
  padding: 2rem;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 1.5rem 0;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children in a column */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  transition: transform 0.4s, box-shadow 0.4s;
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center buttons horizontally */
  gap: 1.8rem;
  width: 100%;
  max-width: 450px;
  margin-top: 1.2rem; /* Add margin-top for spacing below the input */
`;

const Button = styled.button`
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 12px;
  padding: 0.9rem 1.8rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.4s, transform 0.3s;
  &:hover, &:focus {
    background-color: #0069d9;
    transform: scale(1.08);
  }
  &:active {
    transform: scale(0.96);
  }
`;

// const InputField = styled.textarea`
//   padding: 1rem;
//   border: 1px solid ${props => (props.isInvalid ? '#dc3545' : '#ced4da')};
//   border-radius: 8px;
//   margin-bottom: 1.2rem;
//   width: 100%;
//   max-width: 450px;
//   font-size: 1rem;
//   resize: vertical;
//   font-family: 'Inter', sans-serif;
//   transition: border-color 0.3s;
//   &:focus {
//     border-color: #007bff;
//     outline: none;
//   }
// `;

const OneLineInputField = styled.input`
  padding: 1rem;
  border: 1px solid ${props => (props.isInvalid ? '#dc3545' : '#ced4da')};
  border-radius: 8px;
  margin-bottom: 1.2rem; /* Keep this for spacing below the input */
  width: 100%; /* Ensures full width */
  max-width: 100%; /* Ensures it doesn't exceed the card width */
  font-size: 1rem;
  resize: none;
  font-family: 'Inter', sans-serif;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// const GoBackButton = styled(Button)`
//   background-color: #6c757d;
//   padding: 0.7rem 1.4rem;
//   font-size: 1rem;
//   &:hover, &:focus {
//     background-color: #5a6268;
//   }
// `;

// const SkipQuestion = styled(Button)`
//   background-color: #6c757d;
//   padding: 0.7rem 1.4rem;
//   font-size: 1rem;
//   &:hover, &:focus {
//     background-color: #5a6268;
//   }
// `;

const HorizontalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const TopContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  display: flex;
  align-items: center;
`;

const ButtonTopRightContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  gap: 0.8rem;
  margin-top: 30px;
`;

const Logo = styled.img`
  height: 150px;
  width: auto;
  cursor: pointer;
  transition: transform 0.4s;
  &:hover {
    transform: scale(1.07);
  }
`;

const SignOutButton = styled(Button)`
  background-color: #dc3545;
  padding: 0.7rem 1.4rem;
  font-size: 1rem;
  &:hover, &:focus {
    background-color: #c82333;
  }
`;

// const SkipSetup = styled(Button)`
//   background-color: #6c757d;
//   padding: 0.7rem 1.4rem;
//   font-size: 1rem;
//   &:hover, &:focus {
//     background-color: #5a6268;
//   }
// `;

const CardTitle = styled.h3`
  font-size: 1.4rem;
`;

const TravelersSetup = () => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState('');
  const [isDisplayNameValid, setIsDisplayNameValid] = useState(true);
  const [currentCard, setCurrentCard] = useState(1);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!currentUser) return;
        
        const userId = currentUser.uid;
        const userDocRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setDisplayName(userData.displayName || '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    fetchUserData();

    return () => unsubscribe();
  }, [currentUser]);

  const handleSaveName = async () => {
    if (!displayName.trim()) {
      setIsDisplayNameValid(false);
      return;
    }
    setIsDisplayNameValid(true);

    if (!currentUser) {
      console.error('No current user found');
      return;
    }
  
    try {
      const userId = currentUser.uid;
      const userDocRef = doc(db, 'users', userId);
  
      await setDoc(userDocRef, { displayName }, { merge: true });
  
      setCurrentCard(3);
    } catch (error) {
      console.error('Error saving first name:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // const handleSkipSetup = async () => {
  //   navigate('/travelers/dashboard')
  // };

  // const handleGoBack1 = async () => {
  //   setCurrentCard(1);
  // }

  const handleGoForward1 = async () => {
    setCurrentCard(2);
  };

  // const handleGoForward2 = async () => {
  //   setCurrentCard(3);
  // }

  return (
    <PageContainer>
      {/* Top left logo */}
      <TopContainer>
        <Logo src={LogoImage} alt="Logo" onClick={() => navigate('/home')} />
      </TopContainer>
  
      {/* Top right buttons */}
      <ButtonTopRightContainer>
        {/* <SkipSetup onClick={handleSkipSetup}>Skip Setup</SkipSetup> */}
        <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
      </ButtonTopRightContainer>

      <br></br>
      <br></br>

    <h1>Travelers</h1>
      {currentCard === 1 && (
        <Card>
          <CardTitle>Initial Setup</CardTitle>
          <p>Click the button below to continue. You may change your setup anytime in settings.</p>
        
        <HorizontalButtonContainer>
          <Button onClick={handleGoForward1}>Let's go!</Button>
        </HorizontalButtonContainer>
        </Card>
      )}

      {currentCard === 2 && (
        <Card>
          <CardTitle>Name</CardTitle>
          <p>What is your full name?</p>

          <OneLineInputField
            type="text"
            placeholder="Enter your response"
            value={displayName} 
            onChange={(e) => setDisplayName(e.target.value)}
            isInvalid={!isDisplayNameValid}
          />
          <ButtonContainer>
            <Button onClick={handleSaveName}>Next</Button>
            {/* <HorizontalButtonContainer>
              <SkipQuestion onClick={handleGoForward2}>Skip Question</SkipQuestion>
              <GoBackButton onClick={handleGoBack1}>Go Back</GoBackButton>
            </HorizontalButtonContainer> */}
          </ButtonContainer>
        </Card>
      )}
    </PageContainer>
  )

}

export default TravelersSetup;

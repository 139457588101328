import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Import the Firebase auth instance
import logoImage from '../images/logo.png';
import backgroundImage from '../images/SignUpScreenBackground.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';


// Keyframes for sliding in the error message box from the top
const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  min-height: 100vh; /* Ensuring it takes up the full height of the viewport */
  padding: 0; /* Remove any default padding */
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Inter', sans-serif;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 1.5em;
  box-sizing: border-box;
//   cursor: pointer;

  @media (max-width: 768px) {
    padding: 0.75em;
  }

  @media (max-width: 480px) { 
    padding: 0.5em;
    flex-direction: column;
    align-items: flex-start;
  }
`;

// const Header = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 1.75rem;
//   font-weight: 600;
//   color: black;

//   @media (max-width: 768px) {
//     font-size: 1.5rem;
//   }
// `;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;


const Card = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  border: 3px solid #4A90E2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  margin: 0; /* Removed margin-top */
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  font-family: 'SFPro-Regular', sans-serif;
`;

const Button = styled.button`
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 6px;
  padding: 12px 32px;
  font-size: 15px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  width: auto;

  &:hover, &:focus {
    background-color: #333;
    border-color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-family: 'SFPro-Regular', sans-serif;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;

const TogglePasswordButton = styled.button`
  position: absolute;
  right: 35px;
  top: 35%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem; /* Increase the font size */
`;

const SignUpLink = styled.a`
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const InputWithIconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #ccc;
  font-size: 1.1rem; /* Match icon size with input font */
  margin-bottom: 12px;
`;

const InputWithIcon = styled(Input)`
  padding-left: 35px; /* Offset to make room for the icon */
  height: 2.5rem; /* Ensure enough height for centering */
`;

const ErrorMessageBox = styled.div`
  position: absolute;
  top: 20px;
  z-index: 10;
  background-color: #f8d7da;
  color: #721c24;
  padding: 1rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  animation: ${slideDown} 0.5s ease-out;
`;

const LogoTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const HeaderLink = styled.p`
  color: black;
  font-family: 'Geist-Regular', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  text-decoration: none;
//  cursor: pointer;
  padding: 0.4rem 0.6rem;

//   &:hover {
//     font-family: 'Geist-SemiBold', sans-serif;
//   }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const ActionButton = styled.button`
  color: #fff;
  background-color: #000;
  font-family: 'Geist-Regular', sans-serif;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    // font-family: 'Geist-SemiBold', sans-serif;
    transform: scale(1.05);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for error message
  const navigate = useNavigate();

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrorMessage('');

  
    // Validation for empty fields
    if (!email || !password || !confirmPassword) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      console.log("User created:", user);
  
      // Redirect to login page
      navigate('/setup');
    } catch (error) {
      console.error("Error signing up:", error.message);
      alert(`Error: ${error.message}`);
    }
  };

  const handleLogin = () => {
    navigate('/login');
  }

  const handleHomePage = async () => {
    navigate('/');
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <PageContainer>
      {/* Display Error Message */}
  {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
      <HeaderContainer>
        <LogoTextContainer onClick={handleHomePage}>
          <Logo src={logoImage} alt="TravelLocal Logo" />
          {/* <Header>Travel Local</Header> */}
        </LogoTextContainer>
        
        <ButtonGroup>
          <HeaderLink><span2>Have an account?</span2></HeaderLink>
          <ActionButton onClick={handleLogin}>Log in</ActionButton>
        </ButtonGroup>
      </HeaderContainer>
    
      <Card>
        <Title>Sign Up</Title>
        <form onSubmit={handleSignUp}>
            <InputWithIconWrapper>
                <Icon icon={faEnvelope} />
                <InputWithIcon
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
            </InputWithIconWrapper>
            
            <InputWithIconWrapper>
                <Icon icon={faLock} />
                <InputWithIcon
                    type={passwordVisible ? "text" : "password"} 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                <TogglePasswordButton type="button" onClick={togglePasswordVisibility}>
                    {passwordVisible ? '🙈' : '👁️'}
                </TogglePasswordButton>
            </InputWithIconWrapper>

            <InputWithIconWrapper>
                <Icon icon={faLock} />
                <InputWithIcon
                    type="password" 
                    placeholder="Confirm Password" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                    onCopy={(e) => e.preventDefault()} 
                    onCut={(e) => e.preventDefault()} 
                    onPaste={(e) => e.preventDefault()} 
                />
            </InputWithIconWrapper>
            <Button type="submit">Sign Up</Button>
        </form>
        <SignUpLink onClick={handleLogin}>Already have an account? Log in</SignUpLink>
      </Card>
    </PageContainer>
  );
};

export default SignUp; 
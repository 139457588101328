import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { auth, deleteUserAccount, updatePassword } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/logo.png';

// Keyframes for sliding in the error message box from the top
const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  align-items: center;
//   margin-top: 15px;
  overflow-x: hidden; /* Prevent horizontal overflow */
  // animation: ease 0.5s;
  // animation-iteration-count: 1;
  // animation-fill-mode: forwards;
  background-color: #fff;
`;

const HeaderContainer = styled.div`
 background-color: #fff;
  display: flex;
//   justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0.75em;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
  }
`;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  margin-top: 15px; /* Moves logo slightly down */
  cursor: pointer;

  @media (max-width: 768px) {
    height: 60px;
    margin-top: 5px; /* Adjusted for smaller screens */
  }

  @media (max-width: 480px) {
    height: 50px;
    margin-top: 3px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  font-family: 'GeistMono-Bold', sans-serif;
  font-weight: 600;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem; /* Adjusted font size for very small screens */
  }
`;

const SettingsCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  margin: 0; /* Removed margin-top */
`;

const Title = styled.h2`
  font-size: 1.8rem;
  font-family: 'Geist-Medium', sans-serif;
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
`;

const Button = styled.button`
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 12px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Geist-Regular', sans-serif;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;

const ConfirmMessageBox = styled.div`
  position: absolute;
  top: 20px;
  z-index: 10;
  background-color: ${({ type }) => (type === 'success' ? '#d4edda' : '#f8d7da')}; /* Green for success, red for error */
  color: ${({ type }) => (type === 'success' ? '#155724' : '#721c24')};          /* Dark green for success, red for error */
  padding: 1rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  animation: ${slideDown} 0.5s ease-out;
`;

const Settings = () => {
  const [newPassword, setNewPassword] = useState('');
  const [user, setUser] = useState(null);
  const [confirmMessageType, setConfirmMessageType] = useState(''); // New state to track message type
  const [confirmMessage, setConfirmMessage] = useState(''); // New state for error message
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (confirmMessage) {
      const timer = setTimeout(() => {
        setConfirmMessage('');
        setConfirmMessageType('');
      }, 3500); // Hide message after 3.5 seconds
  
      return () => clearTimeout(timer); // Cleanup timer if component unmounts
    }
  }, [confirmMessage]);

  const handleChangePassword = async () => {
    setConfirmMessage('');
    setConfirmMessageType('');

      // Check if newPassword is empty
  if (!newPassword.trim()) {
    setConfirmMessage('Please enter a new password.');
    setConfirmMessageType('error');
    return; // Stop further execution if password field is empty
  }
  
    try {
      await updatePassword(newPassword);
      setConfirmMessage('Password updated successfully!');
      setConfirmMessageType('success');
    } catch (error) {
      console.error('Error updating password:', error);
      setConfirmMessage('Failed to update password.');
      setConfirmMessageType('error');
    }
  };
  
  const handleDeleteAccount = async () => {
    setConfirmMessage('');
    setConfirmMessageType('');
  
    try {
      await deleteUserAccount();
      setConfirmMessage('Account deleted successfully!');
      setConfirmMessageType('success');
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      setConfirmMessage('Failed to delete account.');
      setConfirmMessageType('error');
    }
  };

  const handleLogoImage = async () => {
    navigate('/dashboard');
  }

  return (
    <PageContainer>
         {confirmMessage && <ConfirmMessageBox type={confirmMessageType}>{confirmMessage}</ConfirmMessageBox>}
      <HeaderContainer>
        <Header>
        <Logo src={logoImage} onClick={handleLogoImage} alt="GourmetChef Logo" />
        {/* Travel Local */}
        </Header>
      </HeaderContainer>
     
      <SettingsCard>
        <Title>Settings</Title>
        {user && !user.providerData.some(provider => provider.providerId === 'google.com') && (
          <>
            <Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button onClick={handleChangePassword}>Change Password</Button>
          </>
        )}
        <Button onClick={handleDeleteAccount} style={{ backgroundColor: '#e53935' }}>
          Delete Account
        </Button>
      </SettingsCard>
    </PageContainer>
  );
};

export default Settings;
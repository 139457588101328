import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import logoImage from '../../images/logo.png';
import '../../fonts/fonts.css';
// import MenuButton from "../images/menuButton.png";=
import { auth, db } from '../../firebaseConfig.js';
import { FaCog } from 'react-icons/fa'; // Import a settings icon from FontAwesome
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0;
//   margin-top: 15px;
  overflow-x: hidden; /* Prevent horizontal overflow */
  // animation: ease 0.5s;
  // animation-iteration-count: 1;
  // animation-fill-mode: forwards;
  background-color: #B0C4DE;
`;

const HeaderContainer = styled.div`
 background-color: #B0C4DE;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0.75em;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
  }
`;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 60px;
  }

  @media (max-width: 480px) {
    height: 50px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  font-family: 'GeistMono-Bold', sans-serif;
  font-weight: 600;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem; /* Adjusted font size for very small screens */
  }
`;

const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const CenterText = styled.h1`
  font-size: 3rem;
  font-family: 'Geist-Bold', sans-serif;
  font-weight: 600;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Display ellipsis if text overflows */
  transition: opacity 0.25s ease;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  span {
    font-family: 'SFPro-Semibold', sans-serif;
    background: linear-gradient(to right, blue, hotpink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SubCenterText = styled.h3`
  font-size: 1.1rem;
  font-family: 'Geist-Regular', sans-serif;
  font-weight: 400;
  text-align: center;
  color: gray;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the icon container */
`;

// const ProfileIconButton = styled.div`
//   cursor: pointer;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center; /* Centers the icon inside the button */
//   background-color: #e0e0e0;
//   border-radius: 50%;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
//   transition: transform 0.2s ease;
  
//   &:hover {
//     transform: scale(1.05); /* Slight zoom on hover */
//   }
// `;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 50px; /* Adjusted to be closer to the button */
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  align-items: center;
  border-radius: 8px;
  padding: 8px 0;
  min-width: 150px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 10;
`;

const DropdownItem = styled(Link)`
  display: block;
  text-align: center; /* Centers text inside the dropdown items */
  padding: 10px 16px;
  font-size: 0.95rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-family: 'Geist-Regular', sans-serif;

  &:hover {
    background-color: #f0f4f8;
    color: #0073e6;
  }
`;

const DropdownButton = styled.button`
  color: #fff; /* Text color: white */
  background-color: red; /* Background color: black */
  border: none; /* No border */
  border-radius: 6px; /* Border radius */
  padding: 12px 32px; /* Adjust padding */
  font-size: 0.95rem; /* Increase font size */
  cursor: pointer;
  font-family: 'Geist-Regular', sans-serif;
  width: auto; /* Dynamic width */
  align-items: center;
  margin-left: 18px;

  &:hover, &:focus {
    background-color: red; /* Darker shade of black on hover */
    border-color: #000; /* Keeps border color consistent */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
  }

//   @media (max-width: 768px) {
//      display: none;
//   }
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [firstName, setFirstName] = useState(''); // New state to store first name
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true); // Start loading
      if (user) {
        console.log("User photo URL:", user.photoURL); // Check this value
        setProfilePic(user.photoURL);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const displayName = userDoc.data().displayName;
          const firstNameOnly = displayName ? displayName.split(" ")[0] : "User";
          setFirstName(firstNameOnly);
        } else {
          setFirstName("User");
        }
      } else {
        setProfilePic(null);
        setFirstName("User");
      }
      setIsLoading(false); // Stop loading after fetching
    });
  
    return () => unsubscribe();
  }, []);
  
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <Header>
          <Logo src={logoImage} alt="GourmetChef Logo" />
        </Header>
        
        <ProfileContainer>
          {profilePic ? (
            <img 
              src={profilePic || 'https://via.placeholder.com/40'}
              alt="Profile"
              onClick={toggleDropdown}
              style={{
                cursor: 'pointer',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.2s ease'
              }}
            />
          ) : (
            <FaCog 
    size={25} 
    color="#333" 
    onClick={toggleDropdown} 
    style={{ marginRight: '5px' }} 
/>
          )}

          <ProfileDropdown isOpen={isDropdownOpen}>
            <DropdownItem to="/settings">Settings</DropdownItem>
            <DropdownButton onClick={handleLogout}>Logout</DropdownButton>
          </ProfileDropdown>
        </ProfileContainer>
      </HeaderContainer>

      <FirstContainer>
      <CenterText style={{ opacity: isLoading ? 0 : 1, transition: 'opacity 0.25s ease' }}>
  {isLoading ? "" : `Welcome back, ${firstName}`}
</CenterText>
        <SubCenterText>Choose a feature below</SubCenterText>
      </FirstContainer>
    </PageContainer>
  );
};

export default Dashboard;

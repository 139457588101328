import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { signInWithGoogle, signInWithEmail, auth } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import googleLogo from '../images/google.png'; // Import your Google logo here
import logoImage from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from '../images/LoginScreenBackground.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  min-height: 100vh; /* Ensuring it takes up the full height of the viewport */
  padding: 0; /* Remove any default padding */
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Inter', sans-serif;
`;


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 1.5em;
  box-sizing: border-box;
//   cursor: pointer;

  @media (max-width: 768px) {
    padding: 0.75em;
  }

  @media (max-width: 480px) { 
    padding: 0.5em;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

// const Header = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 1.75rem;
//   font-weight: 600;
//   color: black;

//   @media (max-width: 768px) {
//     font-size: 1.5rem;
//   }
// `;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  border: 3px solid #4A90E2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  margin: 0; /* Removed margin-top */
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
`;

const Button = styled.button`
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 6px;
  padding: 12px 32px;
  font-size: 15px;
  cursor: pointer;
  width: auto;

  &:hover, &:focus {
    background-color: #333;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const GoogleButton = styled.button`
  color: #24292f;
  background-color: #ffffff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  padding: 12px 32px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.25);
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover, &:focus {
    background-color: #f6f8fa;
    box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const GoogleLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;

const SignUpLink = styled.a`
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ActionButton = styled.button`
  color: #fff;
  background-color: #000;
  font-family: 'Geist-Regular', sans-serif;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    // font-family: 'Geist-SemiBold', sans-serif;
    transform: scale(1.05);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const HeaderLink = styled.p`
  color: black;
  font-family: 'Geist-Regular', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  text-decoration: none;
//  cursor: pointer;
  padding: 0.4rem 0.6rem;

//   &:hover {
//     font-family: 'Geist-SemiBold', sans-serif;
//   }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

// Styled components for the icon and input container
// const InputContainer = styled.div`
//   position: relative;
//   width: 100%;
// `;

// Wrapper that holds both the input and icon
const InputWithIconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #ccc;
  font-size: 1.1rem; /* Match icon size with input font */
  margin-bottom: 12px;
`;

const InputWithIcon = styled(Input)`
  padding-left: 35px; /* Offset to make room for the icon */
  height: 2.5rem; /* Ensure enough height for centering */
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRoleRedirect = useCallback(async (uid) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const role = userData.role;

        if (role === 'Local') {
          navigate('/locals/dashboard');
        } else if (role === 'Traveler') {
          navigate('/travelers/dashboard');
        } else {
          navigate('/setup');
        }
      } else {
        console.log("User document doesn't exist, redirecting to setup.");
        navigate('/setup');
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
      navigate('/setup');
    }
  }, [navigate]); // Add navigate to the dependencies of useCallback

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await handleRoleRedirect(user.uid);
      }
    });
    return () => unsubscribe();
  }, [handleRoleRedirect]); // Include handleRoleRedirect in the dependency array

  const handleLogin = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await signInWithEmail(email, password);
      console.log(user);
      await handleRoleRedirect(user.user.uid); // Call role check after login
    } catch (error) {
      console.error("Error signing in with email:", error);
    }
  };

  const handleHomePage = async () => {
    navigate('/');
  };

  const handleForgotPassword = async () => {
    navigate('/forgot-password');
  };


  return (
    <PageContainer>
      <HeaderContainer>
        <LogoTextContainer onClick={handleHomePage}>
          <Logo src={logoImage} alt="TravelLocal Logo" />
          {/* <Header>Travel Local</Header> */}
        </LogoTextContainer>

        <ButtonGroup>
          <HeaderLink><span2>Don't have an account?</span2></HeaderLink>
          <ActionButton onClick={handleSignUp}>Sign Up</ActionButton>
        </ButtonGroup>
      </HeaderContainer>

      <Card>
        <Title>Welcome back!</Title>
        <GoogleButton onClick={handleLogin}>
          <GoogleLogo src={googleLogo} alt="Google Logo" />
          Continue with Google
        </GoogleButton>
        <form>
            
  <InputWithIconWrapper>
    <Icon icon={faEnvelope} />
    <InputWithIcon
      type="text"
      placeholder="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  </InputWithIconWrapper>

<InputWithIconWrapper>
    <Icon icon={faLock} />
  <InputWithIcon
    type="password"
    placeholder="Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
  />
</InputWithIconWrapper>
  <Button type="submit" onClick={handleEmailLogin}>Login</Button>
</form>
        <SignUpLink onClick={handleSignUp}>Sign up</SignUpLink>
        <SignUpLink onClick={handleForgotPassword}>Forgot Password</SignUpLink>
      </Card>
    </PageContainer>
  );
};

export default Login;
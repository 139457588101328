import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { auth } from './firebaseConfig';
import Landing from './views/Landing';
import Login from './views/Login';
import SignUp from './views/SignUp';
import ForgotPassword from './views/ForgotPassword';
import Dashboard from './views/Dashboard';
import Setup from './views/Setup';
import Settings from './views/Settings';
import NotFound from './views/NotFound';

import LocalsSetup from './views/locals/LocalsSetup';
import LocalsDashboard from './views/locals/LocalsDashboard';

import TravelersSetup from './views/travelers/TravelersSetup';
import TravelersDashboard from './views/travelers/TravelersDashboard';

import GlobalStyle from './GlobalStyles';

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);
  
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/setup" element={user ? <Setup /> : <Login/>} />
        {/* <Route path="/setup" element={<Setup/>} /> */}
        <Route path="/dashboard" element={user ? <Dashboard /> : <Login/>} />
        <Route path="/settings" element={<Settings/>} />
        {/* <Route path="/recipeGenerator" element={user ? <RecipeGenerator /> : <Login/>} /> */}

        <Route path="/locals/setup" element={<LocalsSetup/>} />
        <Route path="/locals/dashboard" element={<LocalsDashboard/>} />

        <Route path="/travelers/setup" element={<TravelersSetup/>} />
        <Route path="/travelers/dashboard" element={<TravelersDashboard/>} />
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
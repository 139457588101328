import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/logo.png';
import '../fonts/fonts.css';
import hamburgerIcon from '../images/hamburger-icon.svg'; 

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0;
//   margin-top: 15px;
  overflow-x: hidden; /* Prevent horizontal overflow */
  // animation: ease 0.5s;
  // animation-iteration-count: 1;
  // animation-fill-mode: forwards;
`;

const HeaderContainer = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5em;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0.75em;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
  }
`;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 60px;
  }

  @media (max-width: 480px) {
    height: 50px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  font-family: 'GeistMono-Bold', sans-serif;
  font-weight: 600;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem; /* Adjusted font size for very small screens */
  }
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  position: relative;

  @media (min-width: 768px) {
    .menu-icon {
      display: none; /* Hide hamburger icon on larger devices */
    }
    .menu-content {
      display: none; /* Hide menu content on larger devices */
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MenuIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none; /* Hide menu icon on larger devices */
  }
`;

const MenuContent = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  padding: 20px;
  width: 300px;
  text-align: center; /* Center the text */

  @media (max-width: 768px) {
    right: 0;
    width: 100%;
  }
`;

// const MenuItem = styled.a`
//   display: block;
//   width: 100%; /* Ensure full width for centering */
//   padding: 10px 15px;
//   color: #333;
//   text-decoration: none;
//   text-align: center; /* Center the text */
//   margin-margin: 50px;

//   &:hover {
//     background-color: #f0f0f0;
//   }
// `;

const MenuButton = styled.button`
  display: block;
  width: 100%; /* Ensure full width for centering */
  padding: 8px 13px;
  background-color: #000;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 12px;
  border-radius: 6px;
  justify-content: center;

  &:hover {
    background-color: #333;
  }
`;

const HeaderMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
  color: #444;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  border-radius: 8px; /* Rounded corners */
  font-family: "SFPro-Bold";

  &:hover {
    background-color: #e0e4f1;
  }

  &.active {
    background-color: #e0e4f1; /* Active background */
    border-radius: 8px; /* Ensure rounded corners */
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ActionButton = styled.button`
  color: #fff;
  background-color: #000;
  font-family: 'Geist-Regular', sans-serif;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  
//   &:hover {
//     font-family: 'Geist-SemiBold', sans-serif;
//   }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  @media (max-width: 600px) {
    display: none;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const HeaderLink = styled.a`
  color: black;
  font-family: 'Geist-Regular', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;
  padding: 0.4rem 0.6rem;

&:hover {
    background-color: rgba(245, 246, 247, 1);
    border-radius: 6px; /* Slightly larger rounded corners */
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const LandingContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  flex: 1;

  @media (max-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
    align-items: center; /* Ensure items are centered */
  }
`;

const InternalLandingContainer = styled.div`
  flex: 1;
  text-align: center; /* Center text inside the container */
  padding-right: 0;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

// const ImageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 20px;
// `;

const CenterText = styled.h1`
  font-family: 'Geist-Bold', sans-serif;
  font-size: 4.1rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 16px;

  span {
    font-family: 'SFPro-SemiboldItalic', sans-serif;
    background: linear-gradient(to right, blue, hotpink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const SubCenterText = styled.h3`
  font-size: 1.7rem;
  font-family: 'Geist-Medium', sans-serif;
  font-weight: 400;
  color: #555;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
`;

const CenterTextButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: row; /* Stack buttons vertically */

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const LeftCenterTextButton = styled.button`
  color: #fff; /* Text color: white */
  background-color: #000; /* Background color: black */
  border: none; /* No border */
  border-radius: 6px; /* Border radius */
  padding: 12px 32px; /* Adjust padding */
  font-size: 16px; /* Increase font size */
  cursor: pointer;
  font-family: 'Geist-Regular', sans-serif;
  width: auto; /* Dynamic width */

  &:hover, &:focus {
    background-color: #333; /* Darker shade of black on hover */
    border-color: #000; /* Keeps border color consistent */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
  }

//   @media (max-width: 768px) {
//      display: none;
//   }
`;

const RightCenterTextButton = styled.button`
  color: #24292f; /* Text color */
  background-color: #ffffff; /* Background color */
  border: 1px solid rgba(27, 31, 35, 0.15); /* Border style */
  border-radius: 6px; /* Border radius */
  padding: 12px 32px; /* Adjusted padding to match the height and width */
  font-size: 16px; /* Increased font size */
  font-family: 'Geist-Regular', sans-serif;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.25); /* Subtle shadow */
  transition: background-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */

  &:hover, &:focus {
    background-color: #f6f8fa; /* Lighter background on hover */
    border-color: rgba(27, 31, 35, 0.15);
    box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1); /* More pronounced shadow */
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Landing = () => {
  const secondDivRef = useRef(null);
  const navigate = useNavigate();
//   const [openSections, setOpenSections] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const handleActionButton = () => {
    navigate('/login');
  }

  const handleLearnMore = () => {
    if (secondDivRef.current) {
      secondDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <PageContainer>
        <HeaderContainer>
            <Header>
            <Logo src={logoImage} alt="GourmetChef Logo" />
            {/* Travel Local */}
            </Header>

        <HeaderWrapper>
          <MenuWrapper>
            <MenuIcon src={hamburgerIcon} alt="Menu" onClick={toggleMenu} />
            <MenuContent open={isOpen}>
              <HeaderMenuItem href="/">Home</HeaderMenuItem>
              <HeaderMenuItem href="/feedback">Locals</HeaderMenuItem>
              {/* <HeaderMenuItem href="/docs">Docs</HeaderMenuItem> */}
              <MenuButton onClick={handleActionButton}>Create</MenuButton> {/* Create button */}
            </MenuContent>
          </MenuWrapper>
        </HeaderWrapper>
        
        <ButtonGroup>
                  {/* <HeaderLink href="/environment"><span>Environment</span></HeaderLink>
          <HeaderLink href="https://nimais-organization.gitbook.io/gourmet-chef-docs/about/team" target="_blank" rel="noopener noreferrer"><span2>Docs</span2></HeaderLink> */}
          <HeaderLink href="/feedback"><span2>Locals</span2></HeaderLink>
          <ActionButton onClick={handleActionButton}>Start</ActionButton>
        </ButtonGroup>
      </HeaderContainer>

        <LandingContainer>
        <InternalLandingContainer>
          <br></br>

          <CenterText>
            Travel Like a <span>Local</span>, Connect with Ease
          </CenterText>
          
          <SubCenterText>Connect with Locals and get the best advice for your next trip</SubCenterText>
          
          <CenterTextButtonDiv>
              <LeftCenterTextButton onClick={handleActionButton}>Let's begin</LeftCenterTextButton>
              <RightCenterTextButton onClick={handleLearnMore}>Learn more</RightCenterTextButton>
          </CenterTextButtonDiv>
    
        </InternalLandingContainer>
      </LandingContainer>

      <div ref={secondDivRef}>

      </div>

    </PageContainer>
  )
}

export default Landing;